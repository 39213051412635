import { Injectable } from '@angular/core';
import {
  ActiveState,
  EntityState,
  EntityStore,
  StoreConfig,
} from '@datorama/akita';
import {
  AllPost,
  Chapter,
  Course,
  PostType,
} from '@memberspot/shared/model/course';

export interface ChaptersState
  extends EntityState<Chapter, string>,
    ActiveState<string> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'chapters', resettable: true })
export class ChaptersStore extends EntityStore<ChaptersState> {
  constructor() {
    super();
    this.akitaPreAddEntity = this.akitaPreAddEntity.bind(this);
    this.akitaPreUpdateEntity = this.akitaPreUpdateEntity.bind(this);
  }

  akitaPreAddEntity(chapter: Chapter) {
    const newChapter = {
      ...chapter,
      posts: this.postMapToArray(chapter?.posts),
    };

    return newChapter;
  }

  akitaPreUpdateEntity(prevChapter: Chapter, nextChapter: Chapter) {
    const newChapter = {
      ...nextChapter,
      posts: this.postMapToArray(nextChapter?.posts),
    };

    return newChapter;
  }

  postMapToArray(posts: Record<string, AllPost> | AllPost[]): AllPost[] {
    if (!posts) {
      return [];
    }

    if (posts instanceof Array) {
      return posts;
    }

    const subPrio: Record<string, number> = {};

    let last = 0;

    Object.values(posts)
      .filter((p) => p.type === PostType.SUBCHAPTER)
      .sort((a, b) => a.priority - b.priority)
      .forEach((s) => {
        last += 1000;
        subPrio[s.id] = last;
      });

    const newPosts = Object.values(posts).sort(
      (a, b) =>
        (a?.parent
          ? a.priority + subPrio[a.parent]
          : a.type === PostType.SUBCHAPTER
            ? subPrio[a.id]
            : a.priority) -
        (b?.parent
          ? b.priority + subPrio[b.parent]
          : b.type === PostType.SUBCHAPTER
            ? subPrio[b.id]
            : b.priority),
    );

    return newPosts;
  }

  updateCoursePropertiesInChapters(course: Course) {
    const ids = this.getValue().ids || [];

    if (!ids) {
      return;
    }

    const { noProgress, hideStatsBeforeCourseStarted, disableThumbnail } =
      course;

    this.update(ids, (chapter) => ({
      ...chapter,
      noProgress,
      hideStatsBeforeCourseStarted,
      disableThumbnail,
    }));
  }
}
