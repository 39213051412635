import { Injectable } from '@angular/core';
import { ChapterAdminService } from '@memberspot/admin/course/data-access/chapter';
import { AdminConfigService } from '@memberspot/admin/shared/data-access/config';
import { AllCoursesStore } from '@memberspot/frontend/shared/data-access/all-courses';
import {
  Course,
  CourseBase,
  CourseMessageProperties,
  CourseProperties,
  CreateCourseDtoProperties,
  DuplicateCourseDto,
  DuplicateDemoCourseDto,
  ReorderPayload,
} from '@memberspot/shared/model/course';
import { CourseBoxProperties } from '@memberspot/shared/model/course-box';
import { ThumbnailProperties } from '@memberspot/shared/model/file';
import { GeneratedCourse } from '@memberspot/shared/model/vertex';
import { prioSortFn } from '@memberspot/shared/util/array';
import { tap } from 'rxjs/operators';

import { CoursesStore } from '../state/courses.store';
import { CoursesAdminApiService } from './courses-admin.api.service';

@Injectable({ providedIn: 'root' })
export class AllCoursesAdminService {
  constructor(
    private _coursesMangeApiService: CoursesAdminApiService,
    private _allCoursesStore: AllCoursesStore,
    private _coursesStore: CoursesStore,
    private _adminConfig: AdminConfigService,
    private _chapterAdminService: ChapterAdminService,
  ) {}

  sync(schoolId: string) {
    this._allCoursesStore.setLoading(true);
    this._allCoursesStore.reset();

    return this._coursesMangeApiService.getCoursesList(schoolId).pipe(
      tap((allCourses) => {
        this._allCoursesStore.setLoading(false);
        this._allCoursesStore.set(allCourses?.courses || []);
      }),
    );
  }

  syncCourseDetails(schoolId: string, courseId: string) {
    this._coursesStore.setLoading(true);

    return this._coursesMangeApiService
      .getCourseDetails(schoolId, courseId)
      .pipe(
        tap((course) => {
          this._coursesStore.upsert(course.id, course);
          this._coursesStore.setActive(course.id);
          this._coursesStore.setLoading(false);
        }),
      );
  }

  deleteCourse(schoolId: string, courseId: string) {
    return this._coursesMangeApiService.deleteCourse(schoolId, courseId).pipe(
      tap(() => {
        this._allCoursesStore.remove(courseId);
      }),
    );
  }

  createCourse(data: CreateCourseDtoProperties) {
    return this._coursesMangeApiService.createCourse(data).pipe(
      tap((createdCourse: Course) => {
        this._allCoursesStore.add(createdCourse);
      }),
    );
  }

  createDemoCourse(
    schoolId: string,
    courseId?: string,
    name?: string,
    createOffer?: boolean,
    categoryIds?: string[],
  ) {
    const demoCourseId = this._adminConfig.getDemoCourseId();

    const data: DuplicateDemoCourseDto = {
      schoolId,
      courseId: courseId || demoCourseId,
      name: name || 'Demo Course',
      createOffer: createOffer || false,
      categoryIds: categoryIds || [],
    };

    return this._coursesMangeApiService.createDemoCourse(data).pipe(
      tap((createdCourse: Course) => {
        this._allCoursesStore.add(createdCourse);
      }),
    );
  }

  createGeneratedCourse(schoolId: string, data: GeneratedCourse) {
    return this._coursesMangeApiService
      .createGeneratedCourse(schoolId, data)
      .pipe(
        tap((createdCourse: Course) => {
          this._allCoursesStore.add(createdCourse);
        }),
      );
  }

  duplicateCourse(data: DuplicateCourseDto) {
    return this._coursesMangeApiService.duplicateCourse(data).pipe(
      tap((createdCourse: Course) => {
        this._allCoursesStore.add(createdCourse);
      }),
    );
  }

  reorderCourses(
    schoolId: string,
    { id, newIndex, previousIndex }: ReorderPayload,
  ) {
    // Get the current state of the courses
    const currentCourses = this._allCoursesStore.getValue().entities ?? {};

    // Perform optimistic update
    const coursesArray = [...Object.values(currentCourses)].sort(prioSortFn);

    const [movedCourse] = coursesArray.splice(previousIndex, 1);

    coursesArray.splice(newIndex, 0, movedCourse);

    // Update the store optimistically
    const updatedPriorities = coursesArray.map((course) => ({
      id: course.id,
      priority: course.priority,
    }));

    const coursesRecord: Record<string, CourseBase> = updatedPriorities.reduce(
      (acc, course) => {
        acc[course.id] = {
          ...currentCourses[course.id],
          priority: updatedPriorities.indexOf(course),
        };

        return acc;
      },
      {} as Record<string, CourseBase>,
    );

    this._allCoursesStore.set(coursesRecord);

    return this._coursesMangeApiService
      .reorderCourses(schoolId, {
        id,
        previousIndex: previousIndex,
        newIndex: newIndex,
      })
      .pipe(
        tap((courses) => {
          this._allCoursesStore.set(courses);
        }),
      );
  }

  updateCourse(
    schoolId: string,
    courseId: string,
    properties: CourseProperties,
  ) {
    return this._coursesMangeApiService
      .updateCourse(schoolId, courseId, properties)
      .pipe(
        tap((updatedCourse: Course) => {
          this._allCoursesStore.upsert(updatedCourse.id, updatedCourse);
          this._coursesStore.upsert(updatedCourse.id, updatedCourse);
          this._chapterAdminService.updateChapterPropertiesAfterCourseUpdated(
            updatedCourse,
          );
        }),
      );
  }

  updateCourseBox(
    schoolId: string,
    courseId: string,
    properties: CourseBoxProperties,
  ) {
    return this._coursesMangeApiService
      .updateCourseBox(schoolId, courseId, properties)
      .pipe(
        tap((updatedCourse: Course) => {
          this._allCoursesStore.upsert(updatedCourse.id, updatedCourse);
          this._coursesStore.upsert(updatedCourse.id, updatedCourse);
        }),
      );
  }

  updateCourseMessage(
    schoolId: string,
    courseId: string,
    properties: CourseMessageProperties,
  ) {
    return this._coursesMangeApiService
      .updateCourseMessage(schoolId, courseId, properties)
      .pipe(
        tap((updatedCourse: Course) => {
          this._allCoursesStore.upsert(updatedCourse.id, updatedCourse);
          this._coursesStore.upsert(updatedCourse.id, updatedCourse);
        }),
      );
  }

  setCourseThumbnail(
    schoolId: string,
    courseId: string,
    thumbnailId: string,
    thumbnail: ThumbnailProperties,
  ) {
    return this._coursesMangeApiService
      .setCourseThumbnail(schoolId, courseId, thumbnailId, thumbnail)
      .pipe(
        tap((updatedCourse: Course) => {
          this._allCoursesStore.upsert(updatedCourse.id, updatedCourse);
          this._coursesStore.upsert(updatedCourse.id, updatedCourse);
        }),
      );
  }

  updateCourseInStores(course: Course) {
    this._allCoursesStore.upsert(course.id, course);
    this._coursesStore.upsert(course.id, course);
  }
}
