import { Injectable } from '@angular/core';
import { HttpBackendService } from '@memberspot/frontend/shared/data-access/common';
import { BackendMethod } from '@memberspot/shared/model/backend';
import {
  AllCourses,
  Course,
  CourseBase,
  CourseMessageProperties,
  CourseProperties,
  CreateCourseDtoProperties,
  DuplicateCourseDto,
  DuplicateDemoCourseDto,
  ReorderPayload,
} from '@memberspot/shared/model/course';
import { CourseBoxProperties } from '@memberspot/shared/model/course-box';
import { ThumbnailProperties } from '@memberspot/shared/model/file';
import { GeneratedCourse } from '@memberspot/shared/model/vertex';

@Injectable({ providedIn: 'root' })
export class CoursesAdminApiService {
  constructor(private _http: HttpBackendService) {}

  getCoursesList(schoolId: string) {
    return this._http.generic<AllCourses>({
      url: `/courses/manage/list/${schoolId}`,
      method: BackendMethod.GET,
    });
  }

  getCourseDetails(schoolId: string, courseId: string) {
    return this._http.generic<Course>({
      url: `/courses/manage/${schoolId}/${courseId}`,
      method: BackendMethod.GET,
    });
  }

  deleteCourse(schoolId: string, courseId: string) {
    return this._http.generic({
      url: `/courses/manage/${schoolId}/${courseId}`,
      method: BackendMethod.DELETE,
    });
  }

  createCourse(data: CreateCourseDtoProperties) {
    return this._http.generic<Course>({
      url: `/courses/manage/create/${data.schoolId}`,
      method: BackendMethod.POST,
      data,
    });
  }

  createDemoCourse(data: DuplicateDemoCourseDto) {
    return this._http.generic<Course>({
      url: `/courses/manage/create/${data.schoolId}/demo`,
      method: BackendMethod.POST,
      data,
    });
  }

  createGeneratedCourse(schoolId: string, data: GeneratedCourse) {
    return this._http.generic<Course>({
      url: `/courses/manage/create-generated/${schoolId}`,
      data,
      method: BackendMethod.POST,
    });
  }

  duplicateCourse(data: DuplicateCourseDto) {
    return this._http.generic<Course>({
      url: `/courses/manage/duplicate/${data.schoolId}`,
      data,
      method: BackendMethod.POST,
    });
  }

  reorderCourses(schoolId: string, data: ReorderPayload) {
    return this._http.generic<CourseBase[]>({
      url: `/courses/manage/reorder/${schoolId}`,
      method: BackendMethod.PATCH,
      data,
    });
  }

  updateCourse(schoolId: string, courseId: string, data: CourseProperties) {
    return this._http.generic<Course>({
      url: `/courses/manage/${schoolId}/${courseId}`,
      method: BackendMethod.PATCH,
      data,
    });
  }

  updateCourseBox(
    schoolId: string,
    courseId: string,
    data: CourseBoxProperties,
  ) {
    return this._http.generic<Course>({
      url: `/courses/manage/course-box/${schoolId}/${courseId}`,
      method: BackendMethod.PATCH,
      data,
    });
  }

  updateCourseMessage(
    schoolId: string,
    courseId: string,
    data: CourseMessageProperties,
  ) {
    return this._http.generic<Course>({
      url: `/courses/manage/course-message/${schoolId}/${courseId}`,
      method: BackendMethod.PATCH,
      data,
    });
  }

  setCourseThumbnail(
    schoolId: string,
    courseId: string,
    thumbnailId: string,
    thumbnail: ThumbnailProperties,
  ) {
    return this._http.generic<Course>({
      url: `/courses/manage/set-thumbnail/${schoolId}/${courseId}/${thumbnailId}`,
      method: BackendMethod.PATCH,
      data: thumbnail,
    });
  }
}
