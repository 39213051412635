import { Injectable } from '@angular/core';
import { HttpBackendService } from '@memberspot/frontend/shared/data-access/common';
import { RecursivePartial } from '@memberspot/models';
import { BackendMethod } from '@memberspot/shared/model/backend';
import {
  AddHlsVideoProperties,
  AddVideoProperties,
  AddVimeoBasicVideoProperties,
  AddYoutubeVideoProperties,
  AiLessonProperties,
  AllPost,
  Chapter,
  ChapterCreateProperties,
  ChapterProperties,
  ChapterStateProperties,
  DeleteFileProperties,
  LessonContentProperties,
  LessonCreateProperties,
  LessonFileProperties,
  LessonStateProperties,
  LessonTypeProperties,
  LessonUpdateableProperties,
  MoveLessonProperty,
  PullVideoFromLinkProperties,
  ReorderChapterPayload,
  ReorderLessonProperties,
  UpdateDurationVideoProperties,
  UpdateFilePriorities,
} from '@memberspot/shared/model/course';
import { ThumbnailProperties } from '@memberspot/shared/model/file';

@Injectable({ providedIn: 'root' })
export class ChapterAdminApiService {
  constructor(private _http: HttpBackendService) {}

  getChapters(schoolId: string, courseId: string) {
    return this._http.generic<Chapter[]>({
      url: `/courses/manage/chapter/${schoolId}/${courseId}`,
      method: BackendMethod.GET,
    });
  }

  getChapter(schoolId: string, courseId: string, chapterId: string) {
    return this._http.generic<Chapter>({
      url: `/courses/manage/chapter/${schoolId}/${courseId}/${chapterId}`,
      method: BackendMethod.GET,
    });
  }

  createChapter(
    schoolId: string,
    courseId: string,
    data: ChapterCreateProperties,
  ) {
    return this._http.generic<Chapter>({
      url: `/courses/manage/chapter/create/${schoolId}/${courseId}`,
      method: BackendMethod.POST,
      data,
    });
  }

  deleteChapter(schoolId: string, courseId: string, chapterId: string) {
    return this._http.generic({
      url: `/courses/manage/chapter/${schoolId}/${courseId}/${chapterId}`,
      method: BackendMethod.DELETE,
    });
  }

  duplicateChapter(
    schoolId: string,
    courseId: string,
    chapterId: string,
    targetCourseId?: string,
  ) {
    return this._http.generic({
      url: `/courses/manage/chapter/duplicate/${schoolId}/${courseId}/${chapterId}`,
      method: BackendMethod.POST,
      data: { targetCourseId },
    });
  }

  setChapterThumbnail(
    schoolId: string,
    courseId: string,
    chapterId: string,
    thumbnailId: string,
    thumbnail: ThumbnailProperties,
  ) {
    return this._http.generic<Chapter>({
      url: `/courses/manage/chapter/set-thumbnail/${schoolId}/${courseId}/${chapterId}/${thumbnailId}`,
      method: BackendMethod.PATCH,
      data: thumbnail,
    });
  }

  updateChapter(
    schoolId: string,
    courseId: string,
    chapterId: string,
    data: ChapterProperties,
  ) {
    return this._http.generic<Chapter>({
      url: `/courses/manage/chapter/${schoolId}/${courseId}/${chapterId}`,
      method: BackendMethod.PATCH,
      data,
    });
  }

  updateChapterState(
    schoolId: string,
    courseId: string,
    chapterId: string,
    data: ChapterStateProperties,
  ) {
    return this._http.generic<Chapter>({
      url: `/courses/manage/chapter/state/${schoolId}/${courseId}/${chapterId}`,
      method: BackendMethod.PATCH,
      data,
    });
  }

  reorderChapters(
    schoolId: string,
    courseId: string,
    data: ReorderChapterPayload,
  ) {
    return this._http.generic<Chapter[]>({
      url: `/courses/manage/chapter/reorder/${schoolId}/${courseId}`,
      method: BackendMethod.PATCH,
      data,
    });
  }

  createLesson(
    schoolId: string,
    courseId: string,
    chapterId: string,
    data: LessonCreateProperties,
  ) {
    return this._http.generic<RecursivePartial<AllPost>>({
      url: `/courses/manage/chapter/lesson/${schoolId}/${courseId}/${chapterId}`,
      method: BackendMethod.POST,
      data,
    });
  }

  getLesson(
    schoolId: string,
    courseId: string,
    chapterId: string,
    lessonId: string,
  ) {
    return this._http.generic<RecursivePartial<AllPost>>({
      url: `/courses/manage/chapter/lesson/${schoolId}/${courseId}/${chapterId}/${lessonId}`,
      method: BackendMethod.GET,
    });
  }

  deleteLesson(
    schoolId: string,
    courseId: string,
    chapterId: string,
    postId: string,
  ) {
    return this._http.generic({
      url: `/courses/manage/chapter/lesson/${schoolId}/${courseId}/${chapterId}/${postId}`,
      method: BackendMethod.DELETE,
    });
  }

  duplicateLesson(
    schoolId: string,
    courseId: string,
    chapterId: string,
    lessonId: string,
  ) {
    return this._http.generic<RecursivePartial<AllPost>>({
      url: `/courses/manage/chapter/lesson/duplicate/${schoolId}/${courseId}/${chapterId}/${lessonId}`,
      method: BackendMethod.POST,
    });
  }

  updateLessonState(
    schoolId: string,
    courseId: string,
    chapterId: string,
    lessonId: string,
    data: LessonStateProperties,
  ) {
    return this._http.generic<Chapter>({
      url: `/courses/manage/chapter/lesson/state/${schoolId}/${courseId}/${chapterId}/${lessonId}`,
      method: BackendMethod.POST,
      data,
    });
  }

  updateLesson(
    schoolId: string,
    courseId: string,
    chapterId: string,
    lessonId: string,
    properties: LessonUpdateableProperties,
  ) {
    return this._http.generic<RecursivePartial<AllPost>>({
      url: `/courses/manage/chapter/lesson/${schoolId}/${courseId}/${chapterId}/${lessonId}`,
      method: BackendMethod.PATCH,
      data: properties,
    });
  }

  unlinkExam(
    schoolId: string,
    courseId: string,
    chapterId: string,
    lessonId: string,
  ) {
    return this._http.generic({
      url: `/courses/manage/chapter/lesson/unlink-exam/${schoolId}/${courseId}/${chapterId}/${lessonId}`,
      method: BackendMethod.PATCH,
    });
  }

  linkExam(
    schoolId: string,
    courseId: string,
    chapterId: string,
    lessonId: string,
    examId: string,
  ) {
    return this._http.generic({
      url: `/courses/manage/chapter/lesson/link-exam/${schoolId}/${courseId}/${chapterId}/${lessonId}/${examId}`,
      method: BackendMethod.PATCH,
      data: { examId },
    });
  }

  unlinkCertificate(
    schoolId: string,
    courseId: string,
    chapterId: string,
    lessonId: string,
  ) {
    return this._http.generic({
      url: `/courses/manage/chapter/lesson/unlink-certificate/${schoolId}/${courseId}/${chapterId}/${lessonId}`,
      method: BackendMethod.PATCH,
    });
  }

  linkCertificate(
    schoolId: string,
    courseId: string,
    chapterId: string,
    lessonId: string,
    certificateId: string,
  ) {
    return this._http.generic({
      url: `/courses/manage/chapter/lesson/link-certificate/${schoolId}/${courseId}/${chapterId}/${lessonId}/${certificateId}`,
      method: BackendMethod.PATCH,
    });
  }

  changeLessonType(
    schoolId: string,
    courseId: string,
    chapterId: string,
    lessonId: string,
    data: LessonTypeProperties,
  ) {
    return this._http.generic<RecursivePartial<AllPost>>({
      url: `/courses/manage/chapter/lesson/lesson-type/${schoolId}/${courseId}/${chapterId}/${lessonId}`,
      method: BackendMethod.PATCH,
      data,
    });
  }

  updateLessonContent(
    schoolId: string,
    courseId: string,
    chapterId: string,
    lessonId: string,
    data: LessonContentProperties,
  ) {
    return this._http.generic<RecursivePartial<AllPost>>({
      url: `/courses/manage/chapter/lesson/content/${schoolId}/${courseId}/${chapterId}/${lessonId}`,
      method: BackendMethod.PATCH,
      data,
    });
  }

  moveLessonToChapter(
    schoolId: string,
    courseId: string,
    chapterId: string,
    lessonId: string,
    targetChapterId: string,
    data: MoveLessonProperty,
  ) {
    return this._http.generic<Chapter[]>({
      url: `/courses/manage/chapter/lesson/move-lesson-to-chapter/${schoolId}/${courseId}/${chapterId}/${lessonId}/${targetChapterId}`,
      method: BackendMethod.PATCH,
      data,
    });
  }

  moveLessonInsideChapter(
    schoolId: string,
    courseId: string,
    chapterId: string,
    lessonId: string,
    properties: ReorderLessonProperties,
  ) {
    return this._http.generic<Chapter>({
      url: `/courses/manage/chapter/lesson/move-lesson-inside-chapter/${schoolId}/${courseId}/${chapterId}/${lessonId}`,
      method: BackendMethod.PATCH,
      data: properties,
    });
  }

  setLessonThumbnail(
    schoolId: string,
    courseId: string,
    chapterId: string,
    lessonId: string,
    thumbnailId: string,
    thumbnail: ThumbnailProperties,
  ) {
    return this._http.generic<RecursivePartial<AllPost>>({
      url: `/courses/manage/chapter/lesson/set-thumbnail/${schoolId}/${courseId}/${chapterId}/${lessonId}/${thumbnailId}`,
      method: BackendMethod.PATCH,
      data: thumbnail,
    });
  }

  resetLessonThumbnail(
    schoolId: string,
    courseId: string,
    chapterId: string,
    lessonId: string,
    thumbnailId: string,
  ) {
    return this._http.generic<RecursivePartial<AllPost>>({
      url: `/courses/manage/chapter/lesson/reset-thumbnail/${schoolId}/${courseId}/${chapterId}/${lessonId}/${thumbnailId}`,
      method: BackendMethod.PATCH,
    });
  }

  addFile(
    schoolId: string,
    courseId: string,
    chapterId: string,
    lessonId: string,
    data: LessonFileProperties,
  ) {
    return this._http.generic<RecursivePartial<AllPost>>({
      url: `/courses/manage/chapter/lesson/file/${schoolId}/${courseId}/${chapterId}/${lessonId}`,
      method: BackendMethod.POST,
      data,
    });
  }

  deleteFile(
    schoolId: string,
    courseId: string,
    chapterId: string,
    lessonId: string,
    data: DeleteFileProperties,
  ) {
    return this._http.generic<RecursivePartial<AllPost>>({
      url: `/courses/manage/chapter/lesson/file/unlink/${schoolId}/${courseId}/${chapterId}/${lessonId}`,
      method: BackendMethod.POST,
      data,
    });
  }

  sortFiles(
    schoolId: string,
    courseId: string,
    chapterId: string,
    lessonId: string,
    data: UpdateFilePriorities,
  ) {
    return this._http.generic<RecursivePartial<AllPost>>({
      url: `/courses/manage/chapter/lesson/file/sort/${schoolId}/${courseId}/${chapterId}/${lessonId}`,
      method: BackendMethod.POST,
      data,
    });
  }

  addVideoToLesson(
    schoolId: string,
    courseId: string,
    chapterId: string,
    lessonId: string,
    data: AddVideoProperties,
  ) {
    return this._http.generic<RecursivePartial<AllPost>>({
      url: `/courses/manage/chapter/lesson/video/${schoolId}/${courseId}/${chapterId}/${lessonId}`,
      method: BackendMethod.PATCH,
      data,
    });
  }

  addVideoWithNewLesson(
    schoolId: string,
    courseId: string,
    chapterId: string,
    lessonId: string,
    data: AddVideoProperties,
  ) {
    return this._http.generic<RecursivePartial<AllPost>>({
      url: `/courses/manage/chapter/lesson/video/new-lesson/${schoolId}/${courseId}/${chapterId}/${lessonId}`,
      method: BackendMethod.PATCH,
      data,
    });
  }

  pullVideoFromLinkToLesson(
    schoolId: string,
    courseId: string,
    chapterId: string,
    lessonId: string,
    data: PullVideoFromLinkProperties,
  ) {
    return this._http.generic<RecursivePartial<AllPost>>({
      url: `/courses/manage/chapter/lesson/video/pull-link/${schoolId}/${courseId}/${chapterId}/${lessonId}`,
      method: BackendMethod.PATCH,
      data,
    });
  }

  addYoutubeVideoToLesson(
    schoolId: string,
    courseId: string,
    chapterId: string,
    lessonId: string,
    data: AddYoutubeVideoProperties,
  ) {
    return this._http.generic<RecursivePartial<AllPost>>({
      url: `/courses/manage/chapter/lesson/video/youtube/${schoolId}/${courseId}/${chapterId}/${lessonId}`,
      method: BackendMethod.PATCH,
      data,
    });
  }

  addHlsVideoToLesson(
    schoolId: string,
    courseId: string,
    chapterId: string,
    lessonId: string,
    data: AddHlsVideoProperties,
  ) {
    return this._http.generic<RecursivePartial<AllPost>>({
      url: `/courses/manage/chapter/lesson/video/hls/${schoolId}/${courseId}/${chapterId}/${lessonId}`,
      method: BackendMethod.PATCH,
      data,
    });
  }

  addVimeoBasicVideoToLesson(
    schoolId: string,
    courseId: string,
    chapterId: string,
    lessonId: string,
    data: AddVimeoBasicVideoProperties,
  ) {
    return this._http.generic<RecursivePartial<AllPost>>({
      url: `/courses/manage/chapter/lesson/video/vimeo/${schoolId}/${courseId}/${chapterId}/${lessonId}`,
      method: BackendMethod.PATCH,
      data,
    });
  }

  deleteVideoFromPost(
    schoolId: string,
    courseId: string,
    chapterId: string,
    lessonId: string,
  ) {
    return this._http.generic<RecursivePartial<AllPost>>({
      url: `/courses/manage/chapter/lesson/video/${schoolId}/${courseId}/${chapterId}/${lessonId}`,
      method: BackendMethod.DELETE,
    });
  }

  retryConversion(
    schoolId: string,
    courseId: string,
    chapterId: string,
    lessonId: string,
  ) {
    return this._http.generic<RecursivePartial<AllPost>>({
      url: `/courses/manage/chapter/lesson/video/retry-conversion/${schoolId}/${courseId}/${chapterId}/${lessonId}`,
      method: BackendMethod.POST,
    });
  }

  updateVideoDuration(
    schoolId: string,
    courseId: string,
    chapterId: string,
    lessonId: string,
    data: UpdateDurationVideoProperties,
  ) {
    return this._http.generic<RecursivePartial<AllPost>>({
      url: `/courses/manage/chapter/lesson/video/update-duration/${schoolId}/${courseId}/${chapterId}/${lessonId}`,
      method: BackendMethod.PATCH,
      data,
    });
  }

  updateLessonAiProperties(
    schoolId: string,
    courseId: string,
    chapterId: string,
    lessonId: string,
    data: AiLessonProperties,
  ) {
    return this._http.generic<RecursivePartial<AllPost>>({
      url: `/courses/manage/chapter/lesson/ai-settings/${schoolId}/${courseId}/${chapterId}/${lessonId}`,
      method: BackendMethod.PATCH,
      data,
    });
  }
}
